.showcase {
  display: flex;
  flex-wrap: wrap;
  margin-top: -$grid-gutter-width*2;
  margin-left: -$grid-gutter-width/2;
  margin-right: -$grid-gutter-width/2;

  &__wrapper {
    width: percentage(1/3);
    padding-top: $grid-gutter-width*2;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    @include bp($screen-sm,max){
      width: percentage(1/2);
    }

    @include bp($screen-xs,max){
      width: 100%;
    }
  }

  &__item {
    color: $color-1;

    &:hover {
      text-decoration: none;
    }
  }

  &__image {
    display: block;
    border: 1px solid $color-gray;
  }

  &__title {
    display: block;
    font-weight: bold;
    margin-top: 1rem;
  }

  &__text {
    margin-top: .5rem;
    color: $color-text-light;
    font-size: .875rem;
  }
}