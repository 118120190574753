.contacts {
  .icon-block {
    margin-top: 1rem;
  }

  .button {
    width: 100%;
    margin-top: 2rem;

    @include bp($screen-md, max) {
      width: auto;
    }
  }

  &__map {
    border: 1px solid $color-gray;
  }
}