.fade {
  opacity: 0;
  transition: opacity .15s;

  &.in {
    opacity: 1;
  }
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  z-index: 1300;

  &.fade {
    .modal-dialog {
      transition: transform .3s ease-out;
      transform: translate(0, -50%);
    }
  }

  &.in {
    .modal-dialog {
      transform: translate(0, 0);
    }
  }
}

.modal-dialog {
  position: relative;
  width: 460px;
  margin: 5vh auto $grid-gutter-width;

  @include bp($screen-sm, max) {
    width: 100%;
    margin-top: 0;
  }
}

.modal-content {
  position: relative;
  background-color: #ffffff;
  outline: 0;
  padding: $grid-gutter-width*1.5;
}

.modal-title {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.modal-description {
  margin-bottom: 1rem;
  text-align: center;
}

.modal-close {
  position: absolute;
  top: 0;
  right: -40px;
  font-size: 46px;
  color: #ffffff;
  line-height: 1;

  @include bp($screen-sm, max) {
    color: #000000;
    right: $grid-gutter-width/2;
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: #000000;
  z-index: 1200;

  &.in {
    opacity: .6;
  }
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-open {
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}