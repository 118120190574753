.svg-check {
	width: 18px;
	height: 18px;
}

.svg-clock {
	width: 17px;
	height: 17px;
}

.svg-gib {
	width: 31px;
	height: 31px;
}

.svg-laz {
	width: 20px;
	height: 32px;
}

.svg-link {
	width: 23px;
	height: 21px;
}

.svg-mail {
	width: 17px;
	height: 21px;
}

.svg-marker {
	width: 22px;
	height: 28px;
}

.svg-mat {
	width: 29px;
	height: 29px;
}

.svg-menu {
	width: 18px;
	height: 16px;
}

.svg-money {
	width: 18px;
	height: 18px;
}

.svg-phone {
	width: 27px;
	height: 28px;
}

.svg-pok {
	width: 33px;
	height: 33px;
}

.svg-rub {
	width: 25px;
	height: 32px;
}

.svg-sva {
	width: 21px;
	height: 33px;
}

.svg-upa {
	width: 33px;
	height: 32px;
}

.svg-user {
	width: 350px;
	height: 350px;
}

.svg-val {
	width: 30px;
	height: 26px;
}

