.block-services {
  display: flex;

  @include bp($screen-600, max) {
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    padding: .5rem 0;
    flex: 0 auto;
    background-color: #ffffff;
    box-shadow: 0 0 .25rem rgba(#000, .1);

    @include bp($screen-600, max) {
      width: 100%;
    }
  }
}