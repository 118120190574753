$fonts: "fonts";
$font-1: "Segoe UI", "Trebuchet MS", sans-serif;
$font-2: "Segoe UI", "Trebuchet MS", sans-serif;

@font-face {
  font-family: "Segoe UI";
  src: url("#{$fonts}/segoe-ui/SegoeUI.woff2") format("woff2"),
  url("#{$fonts}/segoe-ui/SegoeUI.woff") format("woff"),
  url("#{$fonts}/segoe-ui/SegoeUI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("#{$fonts}/segoe-ui/SegoeUI-Italic.woff2") format("woff2"),
  url("#{$fonts}/segoe-ui/SegoeUI-Italic.woff") format("woff"),
  url("#{$fonts}/segoe-ui/SegoeUI-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Segoe UI";
  src: url("#{$fonts}/segoe-ui/SegoeUI-Bold.woff2") format("woff2"),
  url("#{$fonts}/segoe-ui/SegoeUI-Bold.woff") format("woff"),
  url("#{$fonts}/segoe-ui/SegoeUI-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}