a,
.link {
  outline: 0;
  color: $color-text;
  cursor: pointer;

  &:hover {
    color: $color-1;
  }

  &[href^=tel] {
    color: inherit;
  }
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
  line-height: 1.2;

  &:first-child {
    margin-top: 0;
  }
}

h1, .h1 {
  margin-top: 0;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px dotted $color-gray;
  color: $color-text;
  text-transform: uppercase;
  font-family: $font-1;
  font-size: 20px;
  font-weight: bold;
}

h2, .h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: $color-text;
  font-family: $font-1;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

h3, .h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: $color-text;
  font-family: $font-1;
  font-size: 18px;
  font-weight: bold;
}

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: $color-text;
  text-transform: uppercase;
  font-family: $font-1;
  font-size: 20px;
  font-weight: bold;
}

ul, ol {
  padding-left: 0;
  margin: 1.5rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  li {
    position: relative;
    margin-top: 1rem;
    list-style: none;
    padding-left: 1.5rem;

    &:before {
      content: "";
      position: absolute;
      top: .5rem;
      left: 0;
      width: 7px;
      height: 7px;
      background-color: $color-2;
      border-radius: 50%;
      margin-right: 1rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}

ol {
  counter-reset: ol-counter;

  li {
    position: relative;
    padding-left: 20px;
    margin: 10px 0;
    list-style: none;
    z-index: 1;

    &::before {
      content: counter(ol-counter) ".";
      counter-increment: ol-counter;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

p {
  margin: 20px 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.lead {
    margin-bottom: 30px;
    font-size: 17px;
    font-weight: normal;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0;
}

.text-primary {
  color: $color-1;
}

.text-gray {
  color: $color-text-light;
}

.text-red {
  color: $color-red;
}

.text-blue {
  color: $color-blue;
}

.text-green {
  color: $color-green;
}

.text-special {
  color: $color-1;
  font-weight: bold;
  text-transform: uppercase;
}

.text-up {
  text-transform: uppercase;
}

.text-price {
  display: inline-block;
  color: $color-1;
  font-size: 30px;
  font-weight: bold;
  font-family: $font-2;

  small {
    display: block;
    color: $color-text-light;
    font-size: $font-size;
    font-family: $font-1;
    font-weight: normal;
    text-align: right;
    line-height: 8px;
  }
}

.text-large {
  font-family: $font-2;
  font-size: 30px;
  font-weight: bold;
}

.text-tip {
  color: $color-text-light;
}

.text-primary {
  color: $color-1;
}

.text-center {
  text-align: center;
}

.text-icon {
  display: flex;
  align-items: center;

  &__text {
  }

  .icon {
    width: 30px;
    margin-right: 10px;
  }

  strong {
    display: block;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
  }

  small {
    display: block;
    font-size: $font-size;
    font-weight: bold;
    line-height: 1;
  }
}

table.table-price {
  overflow-x: scroll;
  width: 100%;
  margin: 2em 0;
  background: #fff;
  border-spacing: 1px;
  border-collapse: separate;

  th, td {
    text-align: center;
  }

  th {
    padding: 1em 0;
    background: #b0b7c0;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }

  td {
    padding: .5em 0;
    background: #e0e7f0;
    color: #555;
    font-size: 10px;
    font-weight: bold;
  }

  tr:nth-child(even) {
    td {
      background: #f1f4f8;
    }
  }
}

.home-text {
  margin-top: $grid-gutter-width*1.5;
  margin-bottom: $grid-gutter-width*1.5;
  padding-top: $grid-gutter-width*1.5;
  padding-bottom: $grid-gutter-width*1.5;
  border-top: 2px dotted $color-gray;
  border-bottom: 2px dotted $color-gray;
}