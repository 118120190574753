.product-form {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem 1.5rem 1.5rem;
  margin-bottom: $grid-gutter-width;
  background: url("#{$img}/product-form.jpg") no-repeat $color-1;

  &__row {
    @include bp($screen-sm, max) {
      margin-top: $grid-gutter-width/2;

      &:first-child {
        margin-top: 0;
      }

      .button {
        width: 100%;
      }
    }
  }

  &__label {
    display: block;
    margin-bottom: .25rem;
    color: #ffffff;
  }

  &__phone {
    width: 140px;

    @include bp($screen-sm, max) {
      width: 100%;
    }
  }

  .form-control {
  }

  @include bp($screen-sm, max) {
    flex-direction: column;
    align-items: stretch;
    background: $color-1;
  }
}