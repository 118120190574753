.novost {
  padding: 1.5rem;
  font-size: 15px;

  &__content {
  }

  &__title {
    display: block;
    margin-top: .75rem;
  }

  &__text {
    margin-top: .75rem;
    font-size: .875rem;
  }

  &__meta {
    color: $color-text-light;

    .icon {
      vertical-align: -3px;
      margin-right: .5rem;
    }
  }
}