.profits {
  display: flex;
  justify-content: space-between;
  margin-bottom: $grid-gutter-width*2;

  &__item {
    display: flex;
    padding-left: $grid-gutter-width;

    &:first-child {
      padding-left: 0;
    }
  }

  &__image {
    flex: 0 0 60px;
  }

  &__text {
    padding-top: .25rem;
    flex: 0 0 auto;
  }

  &__title {
    font-weight: bold;
    line-height: 1;
  }

  &__meta {
    margin-top: .25rem;
    color: $color-text-light;
    font-size: .875rem;
    line-height: 1;
  }

  @include bp($screen-md, max) {
    display: none;
  }
}