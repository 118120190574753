.product {
  display: flex;

  &__image {
    display: inline-block;
    width: auto;
    border: 1px solid $color-gray;
    box-shadow: 0 0 .25rem rgba(#000, .1);
  }

  &__meta {
  }

  &__description {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 2px dotted $color-gray;
  }

  &__contact {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 2px dotted $color-gray;

    div {
      margin-right: 1rem;
    }

    strong {
      font-size: 1.125rem;
    }

    .button {
      margin-left: auto;
    }
  }

  &__type {
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    padding-top: $grid-gutter-width;
    border-top: 2px dotted $color-gray;

    .heading {
      text-transform: uppercase;
    }
  }

  &__text {
    display: flex;
    align-items: flex-start;

    @include bp($screen-sm, max) {
      flex-direction: column;
    }
  }

  &__certs {
    flex: 0 0 260px;
    margin-right: $grid-gutter-width;

    @include bp($screen-sm, max) {
      margin-right: 0;
    }

    a {
      display: block;
      border: 2px solid $color-1;
    }
  }

  &__desc {
  }
}