@charset "UTF-8";
@font-face {
  font-family: "Segoe UI";
  src: url("fonts/segoe-ui/SegoeUI.woff2") format("woff2"), url("fonts/segoe-ui/SegoeUI.woff") format("woff"), url("fonts/segoe-ui/SegoeUI.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Segoe UI";
  src: url("fonts/segoe-ui/SegoeUI-Italic.woff2") format("woff2"), url("fonts/segoe-ui/SegoeUI-Italic.woff") format("woff"), url("fonts/segoe-ui/SegoeUI-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Segoe UI";
  src: url("fonts/segoe-ui/SegoeUI-Bold.woff2") format("woff2"), url("fonts/segoe-ui/SegoeUI-Bold.woff") format("woff"), url("fonts/segoe-ui/SegoeUI-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

html {
  height: 100%;
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

::selection {
  background-color: #ffc451;
  color: #000; }

body {
  height: 100%;
  margin: 0;
  overflow-y: scroll;
  background: url("images/bg.png") repeat #fbfbfb;
  color: #000;
  font-family: "Segoe UI", "Trebuchet MS", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4; }

.container {
  width: 1180px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1179px) {
    .container {
      width: 100%; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  @media (max-width: 479px) {
    .row {
      margin-left: -10px;
      margin-right: -10px; } }
  .row--float {
    display: block; }
    .row--float:before, .row--float:after {
      content: "";
      display: table; }
    .row--float:after {
      clear: both; }
    .row--float [class^=col-] {
      float: left; }
  .row--center {
    align-items: center; }
  .row--stretch {
    align-items: stretch; }
  .row--justify {
    justify-content: space-between; }

[class^=col-] {
  padding-left: 15px;
  padding-right: 15px; }
  @media (max-width: 479px) {
    [class^=col-] {
      padding-left: 10px;
      padding-right: 10px; } }
  @media (max-width: 991px) {
    [class^=col-] + [class^=col-] {
      margin-top: 30px; } }

.col-1 {
  width: 8.33333%; }
  @media (max-width: 991px) {
    .col-1 {
      width: 100%; } }

.col-2 {
  width: 16.66667%; }
  @media (max-width: 991px) {
    .col-2 {
      width: 100%; } }

.col-3 {
  width: 25%; }
  @media (max-width: 991px) {
    .col-3 {
      width: 100%; } }

.col-4 {
  width: 33.33333%; }
  @media (max-width: 991px) {
    .col-4 {
      width: 100%; } }

.col-5 {
  width: 41.66667%; }
  @media (max-width: 991px) {
    .col-5 {
      width: 100%; } }

.col-6 {
  width: 50%; }
  @media (max-width: 991px) {
    .col-6 {
      width: 100%; } }

.col-7 {
  width: 58.33333%; }
  @media (max-width: 991px) {
    .col-7 {
      width: 100%; } }

.col-8 {
  width: 66.66667%; }
  @media (max-width: 991px) {
    .col-8 {
      width: 100%; } }

.col-9 {
  width: 75%; }
  @media (max-width: 991px) {
    .col-9 {
      width: 100%; } }

.col-10 {
  width: 83.33333%; }
  @media (max-width: 991px) {
    .col-10 {
      width: 100%; } }

.col-11 {
  width: 91.66667%; }
  @media (max-width: 991px) {
    .col-11 {
      width: 100%; } }

.col-12 {
  width: 100%; }
  @media (max-width: 991px) {
    .col-12 {
      width: 100%; } }

img {
  vertical-align: top;
  max-width: 100%; }

video {
  max-width: 100%; }

iframe {
  border: 0; }

a,
.link {
  outline: 0;
  color: #000;
  cursor: pointer; }
  a:hover,
  .link:hover {
    color: #1e82c2; }
  a[href^=tel],
  .link[href^=tel] {
    color: inherit; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
  line-height: 1.2; }
  h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child,
  .h1:first-child, .h2:first-child, .h3:first-child, .h4:first-child, .h5:first-child, .h6:first-child {
    margin-top: 0; }

h1, .h1 {
  margin-top: 0;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px dotted #cccccc;
  color: #000;
  text-transform: uppercase;
  font-family: "Segoe UI", "Trebuchet MS", sans-serif;
  font-size: 20px;
  font-weight: bold; }

h2, .h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #000;
  font-family: "Segoe UI", "Trebuchet MS", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold; }

h3, .h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #000;
  font-family: "Segoe UI", "Trebuchet MS", sans-serif;
  font-size: 18px;
  font-weight: bold; }

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #000;
  text-transform: uppercase;
  font-family: "Segoe UI", "Trebuchet MS", sans-serif;
  font-size: 20px;
  font-weight: bold; }

ul, ol {
  padding-left: 0;
  margin: 1.5rem 0; }
  ul:first-child, ol:first-child {
    margin-top: 0; }
  ul:last-child, ol:last-child {
    margin-bottom: 0; }

ul li {
  position: relative;
  margin-top: 1rem;
  list-style: none;
  padding-left: 1.5rem; }
  ul li:before {
    content: "";
    position: absolute;
    top: .5rem;
    left: 0;
    width: 7px;
    height: 7px;
    background-color: #ffc451;
    border-radius: 50%;
    margin-right: 1rem; }
  ul li:first-child {
    margin-top: 0; }

ol {
  counter-reset: ol-counter; }
  ol li {
    position: relative;
    padding-left: 20px;
    margin: 10px 0;
    list-style: none;
    z-index: 1; }
    ol li::before {
      content: counter(ol-counter) ".";
      counter-increment: ol-counter;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 0; }

p {
  margin: 20px 0; }
  p:first-child {
    margin-top: 0; }
  p:last-child {
    margin-bottom: 0; }
  p.lead {
    margin-bottom: 30px;
    font-size: 17px;
    font-weight: normal; }

.title {
  margin-top: 0;
  margin-bottom: 0; }

.text-primary {
  color: #1e82c2; }

.text-gray {
  color: #7f7f7f; }

.text-red {
  color: #c82d2d; }

.text-blue {
  color: #001eff; }

.text-green {
  color: #5d954b; }

.text-special {
  color: #1e82c2;
  font-weight: bold;
  text-transform: uppercase; }

.text-up {
  text-transform: uppercase; }

.text-price {
  display: inline-block;
  color: #1e82c2;
  font-size: 30px;
  font-weight: bold;
  font-family: "Segoe UI", "Trebuchet MS", sans-serif; }
  .text-price small {
    display: block;
    color: #7f7f7f;
    font-size: 16px;
    font-family: "Segoe UI", "Trebuchet MS", sans-serif;
    font-weight: normal;
    text-align: right;
    line-height: 8px; }

.text-large {
  font-family: "Segoe UI", "Trebuchet MS", sans-serif;
  font-size: 30px;
  font-weight: bold; }

.text-tip {
  color: #7f7f7f; }

.text-primary {
  color: #1e82c2; }

.text-center {
  text-align: center; }

.text-icon {
  display: flex;
  align-items: center; }
  .text-icon .icon {
    width: 30px;
    margin-right: 10px; }
  .text-icon strong {
    display: block;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    line-height: 1; }
  .text-icon small {
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1; }

table.table-price {
  overflow-x: scroll;
  width: 100%;
  margin: 2em 0;
  background: #fff;
  border-spacing: 1px;
  border-collapse: separate; }
  table.table-price th, table.table-price td {
    text-align: center; }
  table.table-price th {
    padding: 1em 0;
    background: #b0b7c0;
    color: #fff;
    font-weight: bold;
    font-size: 14px; }
  table.table-price td {
    padding: .5em 0;
    background: #e0e7f0;
    color: #555;
    font-size: 10px;
    font-weight: bold; }
  table.table-price tr:nth-child(even) td {
    background: #f1f4f8; }

.home-text {
  margin-top: 45px;
  margin-bottom: 45px;
  padding-top: 45px;
  padding-bottom: 45px;
  border-top: 2px dotted #cccccc;
  border-bottom: 2px dotted #cccccc; }

.svg-check {
  width: 18px;
  height: 18px; }

.svg-clock {
  width: 17px;
  height: 17px; }

.svg-gib {
  width: 31px;
  height: 31px; }

.svg-laz {
  width: 20px;
  height: 32px; }

.svg-link {
  width: 23px;
  height: 21px; }

.svg-mail {
  width: 17px;
  height: 21px; }

.svg-marker {
  width: 22px;
  height: 28px; }

.svg-mat {
  width: 29px;
  height: 29px; }

.svg-menu {
  width: 18px;
  height: 16px; }

.svg-money {
  width: 18px;
  height: 18px; }

.svg-phone {
  width: 27px;
  height: 28px; }

.svg-pok {
  width: 33px;
  height: 33px; }

.svg-rub {
  width: 25px;
  height: 32px; }

.svg-sva {
  width: 21px;
  height: 33px; }

.svg-upa {
  width: 33px;
  height: 32px; }

.svg-user {
  width: 350px;
  height: 350px; }

.svg-val {
  width: 30px;
  height: 26px; }

.icon {
  display: inline-block;
  fill: currentColor; }

.icon-profit1 {
  background-image: url(images/icons.png);
  background-position: 0px -112px;
  width: 42px;
  height: 43px; }

.icon-profit2 {
  background-image: url(images/icons.png);
  background-position: -42px -112px;
  width: 42px;
  height: 42px; }

.icon-profit3 {
  background-image: url(images/icons.png);
  background-position: -115px -54px;
  width: 46px;
  height: 45px; }

.icon-profit4 {
  background-image: url(images/icons.png);
  background-position: -50px -62px;
  width: 46px;
  height: 45px; }

.icon-scheme1 {
  background-image: url(images/icons.png);
  background-position: -61px 0px;
  width: 54px;
  height: 54px; }

.icon-scheme2 {
  background-image: url(images/icons.png);
  background-position: -115px 0px;
  width: 46px;
  height: 54px; }

.icon-scheme3 {
  background-image: url(images/icons.png);
  background-position: 0px -62px;
  width: 50px;
  height: 50px; }

.icon-scheme4 {
  background-image: url(images/icons.png);
  background-position: 0px 0px;
  width: 61px;
  height: 62px; }

button,
.button {
  overflow: visible;
  vertical-align: top;
  display: inline-block;
  padding: 0;
  border: 0;
  outline: 0;
  background: none;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  font-family: "Segoe UI", "Trebuchet MS", sans-serif;
  line-height: 1;
  cursor: pointer;
  transition: all .15s;
  user-select: none; }
  button[disabled], button.loading,
  .button[disabled],
  .button.loading {
    pointer-events: none; }

.button-primary {
  width: auto;
  padding: 0 1.5rem;
  height: 42px;
  border: 1px solid #1e82c2;
  background-color: #1e82c2;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  line-height: 41px; }
  .button-primary:hover {
    background-color: #4aa7e3;
    color: #fff; }
  .button-primary:active {
    background-color: #4aa7e3;
    color: #fff; }
  .button-primary:focus {
    box-shadow: 0 0 0 1px #1e82c2; }
  .button-primary.loading {
    color: transparent;
    background: url("images/button-primary-loading.gif") no-repeat center center #ffffff;
    pointer-events: none; }

.button-secondary {
  width: auto;
  padding: 0 1.5rem;
  height: 42px;
  border: 1px solid #ffc451;
  background-color: #ffc451;
  color: #000;
  font-size: 16px;
  line-height: 41px;
  text-transform: uppercase;
  font-weight: bold; }
  .button-secondary:hover {
    background-color: #ffde9e;
    color: #000; }
  .button-secondary:active {
    background-color: #ffde9e;
    color: #000; }
  .button-secondary:focus {
    box-shadow: 0 0 0 1px #ffc451; }
  .button-secondary.loading {
    color: transparent;
    background: url("images/button-secondary-loading.gif") no-repeat center center #ffffff;
    pointer-events: none; }

.button-icon .icon {
  vertical-align: -2px;
  margin-right: .75rem; }

form {
  margin: 0; }

.form-group {
  position: relative;
  margin-top: 15px; }
  .form-group label.error {
    position: absolute;
    left: 22px;
    padding: 0 4px;
    bottom: -7px;
    background-color: #ffffff;
    color: #c82d2d;
    font-size: 11px;
    line-height: 18px; }
  .form-group .placeholder {
    position: absolute;
    top: 10px;
    left: 0;
    opacity: 0;
    color: #7f7f7f;
    font-size: 13px;
    line-height: 18px; }
  .form-group:first-child {
    margin-top: 0; }

.form-submit {
  margin-top: 30px;
  text-align: center; }

.form-control {
  overflow: visible;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 42px;
  padding: 0 22px;
  outline: 0;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  color: #000;
  font-family: "Segoe UI", "Trebuchet MS", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 43px;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.2);
  transition: box-shadow .15s; }
  .form-control::placeholder {
    color: #9d9d9d; }
  .form-control:focus {
    box-shadow: 0 0 0 1px #fbd180; }
  .form-control[readonly] {
    pointer-events: none;
    user-select: none; }
  .form-control.error {
    border-color: #c82d2d; }
    .form-control.error::placeholder {
      color: #c82d2d; }
    .form-control.error:focus {
      box-shadow: 0 0 0 1px #c82d2d; }
  .form-control.valid {
    border-color: #5d954b; }
    .form-control.valid::placeholder {
      color: #5d954b; }
    .form-control.valid:focus {
      box-shadow: 0 0 0 1px #5d954b; }

select.form-control {
  padding: 0 1rem; }

textarea.form-control {
  overflow: auto;
  height: 5.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  resize: none;
  line-height: 1.4; }

.top {
  display: flex;
  background-color: #1e82c2; }
  .top .menu-main {
    margin-right: 3rem; }
  .top__wrapper {
    display: flex;
    align-items: center; }
  .top__button {
    margin-left: auto;
    color: #ffffff;
    cursor: pointer; }
    .top__button:hover {
      color: #ffffff; }
  @media (max-width: 991px) {
    .top {
      display: none; } }

.header {
  padding: 30px 0;
  background-color: #ffffff;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1); }
  .header .container {
    display: flex;
    align-items: center; }
    @media (max-width: 767px) {
      .header .container {
        flex-direction: column;
        align-items: flex-start; } }
  .header .icon-block {
    margin-left: 2.5rem; }
    @media (max-width: 767px) {
      .header .icon-block {
        margin-left: 0;
        margin-top: 1rem; } }
  .header__logo {
    display: block;
    width: 254px;
    height: 41px;
    margin-right: auto;
    background-image: url("images/logo.png");
    background-repeat: no-repeat; }
  @media (max-width: 767px) {
    .header {
      padding-top: 15px; } }

.page-content {
  padding: 45px 0 60px; }
  @media (max-width: 767px) {
    .page-content {
      padding-top: 0; } }

.footer {
  padding: 30px 0;
  background-color: #ffffff;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1); }
  .footer__title {
    margin-bottom: .75rem;
    font-weight: bold;
    text-transform: uppercase; }
  .footer__copy {
    margin-top: .75rem;
    margin-bottom: 30px;
    color: #7f7f7f;
    font-size: 14px; }
  @media (min-width: 992px) {
    .footer__contact {
      width: 100%; } }
  @media (max-width: 991px) {
    .footer__menu {
      display: none; } }
  .footer .icon-block {
    margin-top: 1rem; }

.aside {
  margin-top: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1); }
  .aside__title {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 1.5rem;
    background-color: #1e82c2;
    color: #ffffff;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase; }
    .aside__title .icon {
      margin-left: auto; }
      @media (min-width: 992px) {
        .aside__title .icon {
          display: none; } }
  @media (max-width: 767px) {
    .aside--menu {
      margin-left: -15px;
      margin-right: -15px; } }
  @media (max-width: 991px) {
    .aside--news {
      display: none; } }
  .aside--banner {
    position: relative;
    height: 437px; }
    .aside--banner .aside__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("images/banner.jpg");
      text-decoration: none;
      padding: 1.5rem .5rem 2rem; }
      .aside--banner .aside__body:hover {
        color: inherit; }
    .aside--banner small {
      color: #1e82c2;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase; }
    .aside--banner strong {
      text-transform: uppercase;
      color: #1e82c2;
      font-size: 24px;
      margin-bottom: .5rem; }
    .aside--banner .span {
      text-align: center;
      margin-bottom: 1rem;
      font-size: 18px;
      line-height: 1.2; }
    .aside--banner .button {
      margin-top: auto; }
    @media (max-width: 991px) {
      .aside--banner {
        display: none; } }
  .aside:first-child {
    margin-top: 0; }

.menu-main {
  display: flex;
  align-items: center;
  height: 45px; }
  .menu-main__item {
    margin-left: 2.5rem;
    color: #ffffff;
    text-decoration: none; }
    .menu-main__item:hover {
      color: #ffffff;
      text-decoration: underline; }
    .menu-main__item:first-child {
      margin-left: 0; }
    .menu-main__item.active {
      text-decoration: underline; }

.menu-catalog {
  display: flex;
  flex-direction: column; }
  @media (max-width: 991px) {
    .menu-catalog {
      display: none; } }
  .menu-catalog__item {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 15px;
    border-top: 1px solid #fbfbfb;
    color: #000;
    font-size: 18px;
    line-height: 1;
    text-decoration: none; }
    .menu-catalog__item:first-child {
      border-top: 0; }
    .menu-catalog__item:hover {
      background-color: #fbfbfb;
      color: #000; }
    .menu-catalog__item.active {
      font-weight: bold; }
    .menu-catalog__item .icon {
      flex: 0 0 40px;
      margin-right: 1rem; }

.menu-footer__item {
  display: block;
  text-decoration: none;
  margin-top: .5rem; }
  .menu-footer__item:before {
    vertical-align: -2px;
    content: "•";
    color: #ffc451;
    margin-right: .5rem;
    font-size: 1.5rem;
    line-height: 1; }
  .menu-footer__item:first-child {
    margin-top: 0; }
  .menu-footer__item.active {
    font-weight: bold; }

.icon-block {
  display: flex;
  align-items: center;
  line-height: 1.3; }
  .icon-block .icon {
    flex: 0 auto;
    width: 28px; }
  .icon-block .city-select {
    margin-left: 1rem; }
  .icon-block__text {
    margin-left: 1rem; }
  .icon-block__title {
    display: block;
    font-weight: bold;
    font-size: 19px; }
  .icon-block__subtitle {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold; }
  .icon-block__meta {
    display: block;
    color: #7f7f7f;
    font-size: 14px; }

.crumbs {
  margin-top: -20px;
  margin-bottom: 30px;
  font-size: .875rem; }
  .crumbs__link {
    margin-right: 1rem;
    color: #7f7f7f; }
  .crumbs__page {
    color: #1e82c2; }

.contacts .icon-block {
  margin-top: 1rem; }

.contacts .button {
  width: 100%;
  margin-top: 2rem; }
  @media (max-width: 991px) {
    .contacts .button {
      width: auto; } }

.contacts__map {
  border: 1px solid #cccccc; }

.article__name {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .article__name .icon {
    width: 1rem;
    height: 1rem;
    color: #1e82c2;
    margin-right: .5rem; }
  .article__name strong {
    text-transform: uppercase;
    margin-right: 1rem; }
  .article__name small {
    color: #7f7f7f;
    font-size: 1rem;
    margin-right: 1rem; }

.article__title {
  font-weight: bold;
  text-decoration: none; }

.article__text {
  margin-top: 15px; }

.article__footer {
  display: flex;
  align-items: center;
  margin-top: 1rem; }

.article__meta {
  margin-left: auto;
  color: #7f7f7f; }
  .article__meta .icon {
    vertical-align: -3px;
    margin-right: .5rem; }
  @media (max-width: 767px) {
    .article__meta {
      width: 100%;
      margin-top: .25rem; } }

.article__button {
  margin-left: 30px; }

.article__quote {
  position: relative;
  color: #1e82c2;
  padding-left: 6rem;
  margin-top: 1.5rem; }
  .article__quote p {
    display: inline;
    margin: 0; }
  .article__quote:before {
    content: "";
    position: absolute;
    width: 23px;
    height: 23px;
    top: .25rem;
    left: 2rem;
    background-image: url("images/arrow.svg"); }
  .article__quote:after {
    content: "";
    position: absolute;
    top: .25rem;
    left: 4.5rem;
    bottom: .25rem;
    width: 3px;
    background-color: #1e82c2; }

.article-list .article {
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 2px dotted #cccccc; }
  .article-list .article:first-child {
    margin-top: 0; }

.novost {
  padding: 1.5rem;
  font-size: 15px; }
  .novost__title {
    display: block;
    margin-top: .75rem; }
  .novost__text {
    margin-top: .75rem;
    font-size: .875rem; }
  .novost__meta {
    color: #7f7f7f; }
    .novost__meta .icon {
      vertical-align: -3px;
      margin-right: .5rem; }

.block-callback {
  display: flex;
  align-items: center;
  margin-top: 60px;
  height: 90px;
  background: url("images/callback.jpg") no-repeat #1e82c2; }
  .block-callback__text {
    margin-left: auto;
    color: #ffc451;
    text-align: right;
    font-size: 30px;
    line-height: 1;
    text-transform: uppercase; }
    .block-callback__text span {
      line-height: 1; }
    .block-callback__text strong {
      display: block;
      margin-top: .25rem;
      font-size: 25px;
      line-height: 1;
      text-transform: uppercase; }
    @media (max-width: 767px) {
      .block-callback__text {
        text-transform: none;
        margin-left: 0;
        text-align: center; } }
  .block-callback__button {
    flex: 0 0 340px;
    margin-left: 30px; }
    @media (max-width: 767px) {
      .block-callback__button {
        margin-left: 0;
        margin-top: 30px;
        flex: 0 0 auto; } }
  @media (max-width: 767px) {
    .block-callback {
      padding: 1rem;
      flex-direction: column;
      height: auto;
      background: #1e82c2;
      margin-left: -15px;
      margin-right: -15px; } }

.block-form {
  display: flex;
  position: relative;
  padding: 3rem 5rem 2rem 0;
  margin-top: 3rem;
  background: url("images/form.jpg") no-repeat #1e82c2; }
  .block-form__text {
    display: flex;
    flex-direction: column;
    width: 290px;
    flex: 0 auto;
    text-align: center;
    line-height: 1; }
    .block-form__text strong {
      color: #ffc451;
      font-size: 25px;
      text-transform: uppercase; }
    .block-form__text small {
      margin-top: .75rem;
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase; }
    @media (max-width: 767px) {
      .block-form__text {
        width: auto;
        margin-bottom: 30px; } }
  .block-form__form {
    position: relative;
    flex: 1 0;
    z-index: 2; }
  @media (max-width: 767px) {
    .block-form {
      padding: 30px;
      flex-direction: column;
      align-items: stretch;
      background: #1e82c2 !important;
      margin-left: -15px;
      margin-right: -15px; } }

.block-services {
  display: flex; }
  @media (max-width: 599px) {
    .block-services {
      flex-direction: column; } }
  .block-services__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    padding: .5rem 0;
    flex: 0 auto;
    background-color: #ffffff;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1); }
    @media (max-width: 599px) {
      .block-services__item {
        width: 100%; } }

.block-delivery {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 60px; }
  .block-delivery__item {
    display: flex;
    align-items: center;
    width: calc(50% - 1rem);
    padding-top: 2rem;
    flex: 0 auto; }
    .block-delivery__item:nth-child(1), .block-delivery__item:nth-child(3) {
      margin-right: 1rem; }
    .block-delivery__item:nth-child(2), .block-delivery__item:nth-child(4) {
      margin-left: 1rem; }
    .block-delivery__item:nth-child(1), .block-delivery__item:nth-child(2) {
      padding-top: 0;
      padding-bottom: 2rem;
      border-bottom: 2px dotted #cccccc; }
    @media (max-width: 767px) {
      .block-delivery__item {
        width: 100%;
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
        border-bottom: 2px dotted #cccccc !important; }
        .block-delivery__item:first-child {
          border-top: 2px dotted #cccccc; } }
  .block-delivery__image {
    flex: 0 auto;
    width: 230px; }
    @media (max-width: 767px) {
      .block-delivery__image {
        width: 140px; } }
  .block-delivery__title {
    margin-bottom: .5rem;
    font-weight: bold;
    text-transform: uppercase; }
  .block-delivery__meta {
    color: #7f7f7f; }

.block-front {
  margin-top: 45px; }
  .block-front:first-child {
    margin-top: 0; }
  .block-front .heading {
    margin-bottom: 30px; }

.gallery {
  display: flex; }
  .gallery__item {
    padding-left: 30px; }
    .gallery__item:first-child {
      padding-left: 0; }

.showcase {
  display: flex;
  flex-wrap: wrap;
  margin-top: -60px;
  margin-left: -15px;
  margin-right: -15px; }
  .showcase__wrapper {
    width: 33.33333%;
    padding-top: 60px;
    padding-left: 15px;
    padding-right: 15px; }
    @media (max-width: 767px) {
      .showcase__wrapper {
        width: 50%; } }
    @media (max-width: 479px) {
      .showcase__wrapper {
        width: 100%; } }
  .showcase__item {
    color: #1e82c2; }
    .showcase__item:hover {
      text-decoration: none; }
  .showcase__image {
    display: block;
    border: 1px solid #cccccc; }
  .showcase__title {
    display: block;
    font-weight: bold;
    margin-top: 1rem; }
  .showcase__text {
    margin-top: .5rem;
    color: #7f7f7f;
    font-size: .875rem; }

.scheme {
  margin-top: 60px;
  padding-top: 30px;
  border-top: 2px dotted #cccccc; }
  .scheme__header {
    color: #1e82c2;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 30px; }
    @media (max-width: 767px) {
      .scheme__header {
        text-align: center; } }
  .scheme__wrapper {
    display: flex;
    counter-reset: item-counter; }
    @media (max-width: 767px) {
      .scheme__wrapper {
        flex-direction: column; } }
  .scheme__item {
    position: relative;
    width: 25%;
    padding: 2rem 1rem 0;
    text-align: center; }
    .scheme__item::before {
      content: counter(item-counter);
      counter-increment: item-counter;
      position: absolute;
      top: 0;
      left: 1rem;
      width: 42px;
      height: 42px;
      background-color: #c8d4de;
      color: #ffffff;
      font-weight: bold;
      font-size: 25px;
      border-radius: 50%;
      line-height: 42px; }
    @media (max-width: 767px) {
      .scheme__item {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px; }
        .scheme__item:first-child {
          margin-top: 0; } }
  .scheme__link {
    position: absolute;
    right: 38px;
    bottom: 46px; }
    @media (max-width: 767px) {
      .scheme__link {
        display: none; } }

.product {
  display: flex; }
  .product__image {
    display: inline-block;
    width: auto;
    border: 1px solid #cccccc;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1); }
  .product__description {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 2px dotted #cccccc; }
  .product__contact {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 2px dotted #cccccc; }
    .product__contact div {
      margin-right: 1rem; }
    .product__contact strong {
      font-size: 1.125rem; }
    .product__contact .button {
      margin-left: auto; }
  .product__type {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 30px;
    border-top: 2px dotted #cccccc; }
    .product__type .heading {
      text-transform: uppercase; }
  .product__text {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 767px) {
      .product__text {
        flex-direction: column; } }
  .product__certs {
    flex: 0 0 260px;
    margin-right: 30px; }
    @media (max-width: 767px) {
      .product__certs {
        margin-right: 0; } }
    .product__certs a {
      display: block;
      border: 2px solid #1e82c2; }

.product-info {
  display: flex;
  align-items: center;
  margin-top: 1rem; }
  .product-info:first-child {
    margin-top: 0; }
  .product-info .icon {
    margin-right: .5rem; }
  .product-info span {
    margin-right: .5rem;
    color: #7f7f7f; }
    .product-info span.special {
      color: #1e82c2; }
  .product-info strong {
    margin-right: .5rem;
    color: #1e82c2;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 1; }
  .product-info small {
    color: #1e82c2;
    font-size: 22px; }

.product-form {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem 1.5rem 1.5rem;
  margin-bottom: 30px;
  background: url("images/product-form.jpg") no-repeat #1e82c2; }
  @media (max-width: 767px) {
    .product-form__row {
      margin-top: 15px; }
      .product-form__row:first-child {
        margin-top: 0; }
      .product-form__row .button {
        width: 100%; } }
  .product-form__label {
    display: block;
    margin-bottom: .25rem;
    color: #ffffff; }
  .product-form__phone {
    width: 140px; }
    @media (max-width: 767px) {
      .product-form__phone {
        width: 100%; } }
  @media (max-width: 767px) {
    .product-form {
      flex-direction: column;
      align-items: stretch;
      background: #1e82c2; } }

.card__wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1); }

.card__body {
  padding: 1rem 1.25rem; }

.card__title {
  display: block;
  margin-bottom: .5rem;
  text-decoration: none;
  font-weight: bold; }

.card__meta small {
  display: block;
  margin-bottom: .25rem;
  font-size: .875rem; }

.card__value {
  color: #7f7f7f; }
  .card__value:before {
    vertical-align: -2px;
    content: "•";
    color: #ffc451;
    margin-right: .25rem;
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1rem; }

.card__footer {
  margin-top: auto;
  padding: 0 1.25rem 1rem;
  text-align: center; }
  .card__footer small {
    display: block;
    margin-bottom: .5rem;
    font-size: .875rem; }

.card-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -30px;
  margin-left: -15px;
  margin-right: -15px; }
  .card-list .card {
    width: 33.33333%;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px; }
    @media (max-width: 767px) {
      .card-list .card {
        width: 50%; } }
    @media (max-width: 479px) {
      .card-list .card {
        width: 100%; } }
  .card-list .card__wrapper {
    height: 100%; }

.banners {
  margin-bottom: 60px; }
  .banners__item {
    display: flex;
    align-items: center; }
  .banners__text {
    flex: 0 0 50%;
    padding-right: 60px;
    text-align: right; }
    @media (max-width: 991px) {
      .banners__text {
        flex: 0 0 60%; } }
  .banners__button {
    margin-top: 30px; }
  .banners__image {
    flex: 0 0 50%; }
    @media (max-width: 991px) {
      .banners__image {
        flex: 0 0 40%; } }
  @media (max-width: 767px) {
    .banners {
      display: none; } }

.profits {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px; }
  .profits__item {
    display: flex;
    padding-left: 30px; }
    .profits__item:first-child {
      padding-left: 0; }
  .profits__image {
    flex: 0 0 60px; }
  .profits__text {
    padding-top: .25rem;
    flex: 0 0 auto; }
  .profits__title {
    font-weight: bold;
    line-height: 1; }
  .profits__meta {
    margin-top: .25rem;
    color: #7f7f7f;
    font-size: .875rem;
    line-height: 1; }
  @media (max-width: 991px) {
    .profits {
      display: none; } }

.fade {
  opacity: 0;
  transition: opacity .15s; }
  .fade.in {
    opacity: 1; }

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  z-index: 1300; }
  .modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -50%); }
  .modal.in .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog {
  position: relative;
  width: 460px;
  margin: 5vh auto 30px; }
  @media (max-width: 767px) {
    .modal-dialog {
      width: 100%;
      margin-top: 0; } }

.modal-content {
  position: relative;
  background-color: #ffffff;
  outline: 0;
  padding: 45px; }

.modal-title {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase; }

.modal-description {
  margin-bottom: 1rem;
  text-align: center; }

.modal-close {
  position: absolute;
  top: 0;
  right: -40px;
  font-size: 46px;
  color: #ffffff;
  line-height: 1; }
  @media (max-width: 767px) {
    .modal-close {
      color: #000000;
      right: 15px; } }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: #000000;
  z-index: 1200; }
  .modal-backdrop.in {
    opacity: .6; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.link-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -30px;
  margin-left: -15px;
  margin-right: -15px; }
  .link-list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 180px;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px; }
  .link-list__image {
    display: flex;
    align-items: flex-end;
    height: 145px; }
  .link-list__title {
    margin-top: 1rem;
    text-align: center;
    color: #1e82c2; }
    .link-list__title:hover {
      color: #1e82c2;
      text-decoration: none; }

.city-select {
  color: #1e82c2; }

.page-cover:before, .page-cover:after {
  content: "";
  display: table; }

.page-cover:after {
  clear: both; }

.page-cover__image {
  float: left;
  max-width: 50%;
  border: 1px solid #cccccc;
  margin-right: 30px;
  margin-bottom: 30px; }
  @media (max-width: 599px) {
    .page-cover__image {
      float: none;
      margin-right: 0;
      max-width: none; } }
  .page-cover__image + p,
  .page-cover__image + ul,
  .page-cover__image + ol {
    margin-top: 0; }
