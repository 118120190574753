.page-cover {
  @include clearfix;

  &__image {
    float: left;
    max-width: 50%;
    border: 1px solid $color-gray;
    margin-right: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;

    @include bp($screen-600, max) {
      float: none;
      margin-right: 0;
      max-width: none;
    }

    & + p,
    & + ul,
    & + ol {
      margin-top: 0;
    }
  }
}