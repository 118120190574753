.form-control {
  overflow: visible;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: $input-height;
  padding: 0 $input-padding;
  outline: 0;
  border: 1px solid $color-gray;
  background-color: #ffffff;
  color: $color-text;
  font-family: $font-1;
  font-weight: normal;
  font-size: $font-size;
  line-height: $input-height + 1;
  box-shadow: inset 1px 1px 4px rgba(#000, .2);
  transition: box-shadow .15s;

  &::placeholder {
    color: $color-gray-dark;
  }

  &:hover {
  }

  &:focus {
    box-shadow: 0 0 0 1px #fbd180;
  }

  &[required] {
  }

  &[readonly] {
    pointer-events: none;
    user-select: none;
  }

  &.error {
    border-color: $color-red;

    &::placeholder {
      color: $color-red;
    }

    &:focus {
      box-shadow: 0 0 0 1px $color-red;
    }
  }

  &.valid {
    border-color: $color-green;

    &::placeholder {
      color: $color-green;
    }

    &:focus {
      box-shadow: 0 0 0 1px $color-green;
    }
  }
}

select.form-control {
  padding: 0 1rem;
}

textarea.form-control {
  overflow: auto;
  height: 5.5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  resize: none;
  line-height: $line-height;
}