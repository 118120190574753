.header {
  padding: $grid-gutter-width 0;
  background-color: #ffffff;
  box-shadow: 0 0 .25rem rgba(#000, .1);

  .container {
    display: flex;
    align-items: center;

    @include bp($screen-sm, max) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .icon-block {
    margin-left: 2.5rem;

    @include bp($screen-sm, max) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }

  &__logo {
    display: block;
    width: 254px;
    height: 41px;
    margin-right: auto;
    background-image: url("#{$img}/logo.png");
    background-repeat: no-repeat;

    @include bp($screen-sm, max) {
      //width: 160px;
      //height: 26px;
      //background-size: contain;
    }
  }

  @include bp($screen-sm, max) {
    padding-top: $grid-gutter-width/2;
  }
}