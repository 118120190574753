.scheme {
  margin-top: $grid-gutter-width*2;
  padding-top: $grid-gutter-width;
  border-top: 2px dotted $color-gray;

  &__header {
    color: $color-1;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: $grid-gutter-width;

    @include bp($screen-sm, max) {
      text-align: center;
    }
  }

  &__wrapper {
    display: flex;
    counter-reset: item-counter;

    @include bp($screen-sm, max) {
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    width: 25%;
    padding: 2rem 1rem 0;
    text-align: center;

    &::before {
      content: counter(item-counter);
      counter-increment: item-counter;
      position: absolute;
      top: 0;
      left: 1rem;
      width: 42px;
      height: 42px;
      background-color: #c8d4de;
      color: #ffffff;
      font-weight: bold;
      font-size: 25px;
      border-radius: 50%;
      line-height: 42px;
    }

    @include bp($screen-sm, max) {
      width: 200px;
      margin-left: auto;
      margin-right: auto;
      margin-top: $grid-gutter-width;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__title {
  }

  &__link {
    position: absolute;
    right: 38px;
    bottom: 46px;

    @include bp($screen-sm, max) {
      display: none;
    }
  }
}