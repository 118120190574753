.aside {
  margin-top: $grid-gutter-width;
  background-color: #ffffff;
  box-shadow: 0 0 .25rem rgba(#000, .1);

  &__title {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 1.5rem;
    background-color: $color-1;
    color: #ffffff;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;

    .icon {
      margin-left: auto;
      @include bp($screen-md) {
        display: none;
      }
    }
  }

  &__body {
  }

  &--menu {
    @include bp($screen-sm, max) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &--news {
    @include bp($screen-md, max) {
      display: none;
    }
  }

  &--banner {
    position: relative;
    height: 437px;

    .aside__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("#{$img}/banner.jpg");
      text-decoration: none;
      padding: 1.5rem .5rem 2rem;

      &:hover {
        color: inherit;
      }
    }

    small {
      color: $color-1;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }

    strong {
      text-transform: uppercase;
      color: $color-1;
      font-size: 24px;
      margin-bottom: .5rem;
    }

    .span {
      text-align: center;
      margin-bottom: 1rem;
      font-size: 18px;
      line-height: 1.2;
    }

    .button {
      margin-top: auto;
    }

    @include bp($screen-md, max) {
      display: none;
    }
  }

  &:first-child {
    margin-top: 0;
  }
}