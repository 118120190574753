.block-form {
  display: flex;
  position: relative;
  padding: 3rem 5rem 2rem 0;
  margin-top: 3rem;
  background: url("#{$img}/form.jpg") no-repeat $color-1;

  &__text {
    display: flex;
    flex-direction: column;
    width: 290px;
    flex: 0 auto;
    text-align: center;
    line-height: 1;

    strong {
      color: $color-2;
      font-size: 25px;
      text-transform: uppercase;
    }

    small {
      margin-top: .75rem;
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }

    @include bp($screen-sm, max) {
      width: auto;
      margin-bottom: $grid-gutter-width;
    }
  }

  &__form {
    position: relative;
    flex: 1 0;
    z-index: 2;
  }

  @include bp($screen-sm, max) {
    padding: $grid-gutter-width;
    flex-direction: column;
    align-items: stretch;
    background: $color-1 !important;
    margin-left: -15px;
    margin-right: -15px;
  }
}