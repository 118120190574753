// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$profit1-name: 'profit1';
$profit1-x: 0px;
$profit1-y: 112px;
$profit1-offset-x: 0px;
$profit1-offset-y: -112px;
$profit1-width: 42px;
$profit1-height: 43px;
$profit1-total-width: 161px;
$profit1-total-height: 155px;
$profit1-image: 'images/icons.png';
$profit1: (0px, 112px, 0px, -112px, 42px, 43px, 161px, 155px, 'images/icons.png', 'profit1', );
$profit2-name: 'profit2';
$profit2-x: 42px;
$profit2-y: 112px;
$profit2-offset-x: -42px;
$profit2-offset-y: -112px;
$profit2-width: 42px;
$profit2-height: 42px;
$profit2-total-width: 161px;
$profit2-total-height: 155px;
$profit2-image: 'images/icons.png';
$profit2: (42px, 112px, -42px, -112px, 42px, 42px, 161px, 155px, 'images/icons.png', 'profit2', );
$profit3-name: 'profit3';
$profit3-x: 115px;
$profit3-y: 54px;
$profit3-offset-x: -115px;
$profit3-offset-y: -54px;
$profit3-width: 46px;
$profit3-height: 45px;
$profit3-total-width: 161px;
$profit3-total-height: 155px;
$profit3-image: 'images/icons.png';
$profit3: (115px, 54px, -115px, -54px, 46px, 45px, 161px, 155px, 'images/icons.png', 'profit3', );
$profit4-name: 'profit4';
$profit4-x: 50px;
$profit4-y: 62px;
$profit4-offset-x: -50px;
$profit4-offset-y: -62px;
$profit4-width: 46px;
$profit4-height: 45px;
$profit4-total-width: 161px;
$profit4-total-height: 155px;
$profit4-image: 'images/icons.png';
$profit4: (50px, 62px, -50px, -62px, 46px, 45px, 161px, 155px, 'images/icons.png', 'profit4', );
$scheme1-name: 'scheme1';
$scheme1-x: 61px;
$scheme1-y: 0px;
$scheme1-offset-x: -61px;
$scheme1-offset-y: 0px;
$scheme1-width: 54px;
$scheme1-height: 54px;
$scheme1-total-width: 161px;
$scheme1-total-height: 155px;
$scheme1-image: 'images/icons.png';
$scheme1: (61px, 0px, -61px, 0px, 54px, 54px, 161px, 155px, 'images/icons.png', 'scheme1', );
$scheme2-name: 'scheme2';
$scheme2-x: 115px;
$scheme2-y: 0px;
$scheme2-offset-x: -115px;
$scheme2-offset-y: 0px;
$scheme2-width: 46px;
$scheme2-height: 54px;
$scheme2-total-width: 161px;
$scheme2-total-height: 155px;
$scheme2-image: 'images/icons.png';
$scheme2: (115px, 0px, -115px, 0px, 46px, 54px, 161px, 155px, 'images/icons.png', 'scheme2', );
$scheme3-name: 'scheme3';
$scheme3-x: 0px;
$scheme3-y: 62px;
$scheme3-offset-x: 0px;
$scheme3-offset-y: -62px;
$scheme3-width: 50px;
$scheme3-height: 50px;
$scheme3-total-width: 161px;
$scheme3-total-height: 155px;
$scheme3-image: 'images/icons.png';
$scheme3: (0px, 62px, 0px, -62px, 50px, 50px, 161px, 155px, 'images/icons.png', 'scheme3', );
$scheme4-name: 'scheme4';
$scheme4-x: 0px;
$scheme4-y: 0px;
$scheme4-offset-x: 0px;
$scheme4-offset-y: 0px;
$scheme4-width: 61px;
$scheme4-height: 62px;
$scheme4-total-width: 161px;
$scheme4-total-height: 155px;
$scheme4-image: 'images/icons.png';
$scheme4: (0px, 0px, 0px, 0px, 61px, 62px, 161px, 155px, 'images/icons.png', 'scheme4', );
$spritesheet-width: 161px;
$spritesheet-height: 155px;
$spritesheet-image: 'images/icons.png';
$spritesheet-sprites: ($profit1, $profit2, $profit3, $profit4, $scheme1, $scheme2, $scheme3, $scheme4, );
$spritesheet: (161px, 155px, 'images/icons.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
