.gallery {
  display: flex;

  &__item {
    padding-left: $grid-gutter-width;

    &:first-child{
      padding-left: 0;
    }
  }
}