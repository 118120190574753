.icon-block {
  display: flex;
  align-items: center;
  line-height: 1.3;

  .icon {
    flex: 0 auto;
    width: 28px;
  }

  .city-select {
    margin-left: 1rem;
  }

  &__text {
    margin-left: 1rem;
  }

  &__title {
    display: block;
    font-weight: bold;
    font-size: 19px;
  }

  &__subtitle {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__meta {
    display: block;
    color: $color-text-light;
    font-size: 14px;
  }
}