button,
.button {
  overflow: visible;
  vertical-align: top;
  display: inline-block;
  padding: 0;
  border: 0;
  outline: 0;
  background: none;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  font-family: $font-1;
  line-height: 1;
  cursor: pointer;
  transition: all .15s;
  user-select: none;

  &[disabled],
  &.loading {
    pointer-events: none;
  }
}

.button-primary {
  width: auto;
  padding: 0 1.5rem;
  height: $input-height;
  border: 1px solid $color-1;
  background-color: $color-1;
  color: #ffffff;
  font-size: $font-size;
  font-weight: bold;
  line-height: $input-height - 1;

  &:hover {
    background-color: lighten($color-1, 15%);
    color: #fff;
  }

  &:active {
    background-color: lighten($color-1, 15%);
    color: #fff;
  }

  &:focus {
    box-shadow: 0 0 0 1px $color-1;
  }

  &.loading {
    color: transparent;
    background: url("#{$img}/button-primary-loading.gif") no-repeat center center #ffffff;
    pointer-events: none;
  }
}

.button-secondary {
  width: auto;
  padding: 0 1.5rem;
  height: $input-height;
  border: 1px solid $color-2;
  background-color: $color-2;
  color: $color-text;
  font-size: $font-size;
  line-height: $input-height - 1;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    background-color: lighten($color-2, 15%);
    color: $color-text;
  }

  &:active {
    background-color: lighten($color-2, 15%);
    color: $color-text;
  }

  &:focus {
    box-shadow: 0 0 0 1px $color-2;
  }

  &.loading {
    color: transparent;
    background: url("#{$img}/button-secondary-loading.gif") no-repeat center center #ffffff;
    pointer-events: none;
  }
}

.button-icon {
  .icon {
    vertical-align: -2px;
    margin-right: .75rem;
  }
}