.product-info {
  display: flex;
  align-items: center;
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }

  .icon {
    margin-right: .5rem;
  }

  span {
    margin-right: .5rem;
    color: $color-text-light;

    &.special {
      color: $color-1;
    }
  }

  strong {
    margin-right: .5rem;
    color: $color-1;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 1;
  }

  small {
    color: $color-1;
    font-size: 22px;
  }
}