.top {
  display: flex;
  background-color: $color-1;

  .menu-main {
    margin-right: 3rem;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__button {
    margin-left: auto;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      color: #ffffff;
    }
  }

  @include bp($screen-md, max) {
    display: none;
  }
}