.link-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -$grid-gutter-width;
  margin-left: -$grid-gutter-width/2;
  margin-right: -$grid-gutter-width/2;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 180px;
    padding-top: $grid-gutter-width;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }

  &__image {
    display: flex;
    align-items: flex-end;
    height: 145px;
  }

  &__title {
    margin-top: 1rem;
    text-align: center;
    color: $color-1;

    &:hover {
      color: $color-1;
      text-decoration: none;
    }
  }
}