.footer {
  padding: $grid-gutter-width 0;
  background-color: #ffffff;
  box-shadow: 0 0 .25rem rgba(#000, .1);

  &__title {
    margin-bottom: .75rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__copy {
    margin-top: .75rem;
    margin-bottom: $grid-gutter-width;
    color: $color-text-light;
    font-size: 14px;
  }

  &__contact {
    @include bp($screen-md) {
      width: 100%;
    }
  }

  &__menu {
    @include bp($screen-md, max) {
      display: none;
    }
  }

  .icon-block {
    margin-top: 1rem;
  }
}