.block-delivery {
  display: flex;
  flex-wrap: wrap;
  margin-top: $grid-gutter-width*2;
  margin-bottom: $grid-gutter-width*2;

  &__item {
    display: flex;
    align-items: center;
    width: calc(50% - 1rem);
    padding-top: 2rem;
    flex: 0 auto;

    &:nth-child(1),
    &:nth-child(3) {
      margin-right: 1rem;
    }

    &:nth-child(2),
    &:nth-child(4) {
      margin-left: 1rem;
    }

    &:nth-child(1),
    &:nth-child(2) {
      padding-top: 0;
      padding-bottom: 2rem;
      border-bottom: 2px dotted $color-gray;
    }

    @include bp($screen-sm, max) {
      width: 100%;
      margin-right: 0 !important;
      margin-left: 0 !important;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
      border-bottom: 2px dotted $color-gray !important;

      &:first-child {
        border-top: 2px dotted $color-gray;
      }
    }
  }

  &__image {
    flex: 0 auto;
    width: 230px;

    @include bp($screen-sm, max) {
      width: 140px;
    }
  }

  &__text {
  }

  &__title {
    margin-bottom: .5rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__meta {
    color: $color-text-light;
  }
}