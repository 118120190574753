$img: "images";

$font-size: 16px;
$line-height: 1.4;

$input-height: 42px;
$input-padding: 22px;

$color-1: #1e82c2;
$color-2: #ffc451;
$color-3: #d200eb;

$color-text: #000;
$color-text-light: #7f7f7f;

$color-gray: #cccccc;
$color-gray-light: #fbfbfb;
$color-gray-dark: #9d9d9d;

$color-red: #c82d2d;
$color-green: #5d954b;
$color-blue: #001eff;

$grid-columns: 12;
$grid-gutter-width: 30px;

$screen-xs: 480px;
$screen-600: 600px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1240px;