.block-callback {
  display: flex;
  align-items: center;
  margin-top: $grid-gutter-width*2;
  height: 90px;
  background: url("#{$img}/callback.jpg") no-repeat $color-1;

  &__text {
    margin-left: auto;
    color: $color-2;
    text-align: right;
    font-size: 30px;
    line-height: 1;
    text-transform: uppercase;

    span {
      line-height: 1;
    }

    strong {
      display: block;
      margin-top: .25rem;
      font-size: 25px;
      line-height: 1;
      text-transform: uppercase;
    }

    @include bp($screen-sm, max) {
      text-transform: none;
      margin-left: 0;
      text-align: center;
    }
  }

  &__button {
    flex: 0 0 340px;
    margin-left: $grid-gutter-width;

    @include bp($screen-sm, max) {
      margin-left: 0;
      margin-top: $grid-gutter-width;
      flex: 0 0 auto;
    }
  }

  @include bp($screen-sm, max) {
    padding: 1rem;
    flex-direction: column;
    height: auto;
    background: $color-1;
    margin-left: -15px;
    margin-right: -15px;
  }
}