form {
  margin: 0;
}

.form {
}

.form-group {
  position: relative;
  margin-top: $grid-gutter-width/2;

  label.error {
    position: absolute;
    left: $input-padding;
    padding: 0 4px;
    bottom: -7px;
    background-color: #ffffff;
    color: $color-red;
    font-size: 11px;
    line-height: 18px;
  }

  .placeholder {
    position: absolute;
    top: 10px;
    left: 0;
    opacity: 0;
    color: $color-text-light;
    font-size: 13px;
    line-height: 18px;
  }

  &.required {}

  &:first-child {
    margin-top: 0;
  }
}

.form-submit {
  margin-top: $grid-gutter-width;
  text-align: center;
}