@mixin columns {
  @for $i from 1 through $grid-columns {
    @include column($i);
  }
}

@mixin column($width) {
  .col-#{$width} {
    width: percentage($width/$grid-columns);

    @include bp($screen-md, max) {
      width: 100%;
    }
  }
}

.container {
  width: 1180px;
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
  margin-left: auto;
  margin-right: auto;

  @include bp(1180px, max) {
    width: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$grid-gutter-width/2;
  margin-right: -$grid-gutter-width/2;

  @include bp($screen-xs, max) {
    margin-left: -10px;
    margin-right: -10px;
  }

  &--float {
    display: block;
    @include clearfix;

    [class^=col-] {
      float: left;
    }
  }

  &--center {
    align-items: center;
  }

  &--stretch {
    align-items: stretch;
  }

  &--justify {
    justify-content: space-between;
  }
}

[class^=col-] {
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;

  @include bp($screen-xs, max) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @include bp($screen-md, max) {
    & + & {
      margin-top: $grid-gutter-width;
    }
  }
}

@include columns;