.card {
  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 0 .25rem rgba(#000, .1);
  }

  &__image {
  }

  &__body {
    padding: 1rem 1.25rem;
  }

  &__title {
    display: block;
    margin-bottom: .5rem;
    text-decoration: none;
    font-weight: bold;
  }

  &__meta {
    small {
      display: block;
      margin-bottom: .25rem;
      font-size: .875rem;
    }
  }

  &__list {
  }

  &__value {
    color: $color-text-light;

    &:before {
      vertical-align: -2px;
      content: "•";
      color: $color-2;
      margin-right: .25rem;
      font-size: 1.75rem;
      font-weight: bold;
      line-height: 1rem;
    }
  }

  &__footer {
    margin-top: auto;
    padding: 0 1.25rem 1rem;
    text-align: center;

    small {
      display: block;
      margin-bottom: .5rem;
      font-size: .875rem;
    }
  }
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: -$grid-gutter-width;
  margin-left: -$grid-gutter-width/2;
  margin-right: -$grid-gutter-width/2;

  .card {
    width: percentage(1/3);
    padding-top: $grid-gutter-width;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;

    @include bp($screen-sm, max) {
      width: percentage(1/2);
    }

    @include bp($screen-xs, max) {
      width: 100%;
    }
  }

  .card__wrapper {
    height: 100%;
  }
}