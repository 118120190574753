.menu-catalog {
  display: flex;
  flex-direction: column;

  @include bp($screen-md, max) {
    display: none;
  }

  &__item {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 0 $grid-gutter-width/2;
    border-top: 1px solid $color-gray-light;
    color: $color-text;
    font-size: 18px;
    line-height: 1;
    text-decoration: none;

    &:first-child {
      border-top: 0;
    }

    &:hover {
      background-color: $color-gray-light;
      color: $color-text;
    }

    &.active {
      font-weight: bold;
    }

    .icon {
      flex: 0 0 40px;
      margin-right: 1rem;
    }
  }
}