.menu-footer {
  &__item {
    display: block;
    text-decoration: none;
    margin-top: .5rem;

    &:before {
      vertical-align: -2px;
      content: "•";
      color: $color-2;
      margin-right: .5rem;
      font-size: 1.5rem;
      line-height: 1;
    }

    &:hover {
    }

    &:first-child {
      margin-top: 0;
    }

    &.active {
      font-weight: bold;
    }
  }
}