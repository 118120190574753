.banners {
  margin-bottom: $grid-gutter-width*2;

  &__item {
    display: flex;
    align-items: center;
  }

  &__text {
    flex: 0 0 50%;
    padding-right: $grid-gutter-width*2;
    text-align: right;

    @include bp($screen-md, max) {
      flex: 0 0 60%;
    }
  }

  &__button {
    margin-top: $grid-gutter-width;
  }

  &__image {
    flex: 0 0 50%;

    @include bp($screen-md, max) {
      flex: 0 0 40%;
    }
  }

  @include bp($screen-sm, max) {
    display: none;
  }
}