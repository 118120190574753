@import "png";
@import "svg";

@mixin sprites-normal($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .icon-#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

@mixin icons {
  @include sprites-normal($spritesheet-sprites);
}

.icon {
  display: inline-block;
  fill: currentColor;
}

@include icons;