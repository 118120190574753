.crumbs {
  margin-top: -$grid-gutter-width/1.5;
  margin-bottom: $grid-gutter-width;
  font-size: .875rem;

  &__link {
    margin-right: 1rem;
    color: $color-text-light;
  }

  &__page {
    color: $color-1;
  }
}