.article {
  &__content {
  }

  &__name {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .icon {
      width: 1rem;
      height: 1rem;
      color: $color-1;
      margin-right: .5rem;
    }

    strong {
      text-transform: uppercase;
      margin-right: 1rem;
    }

    small {
      color: $color-text-light;
      font-size: 1rem;
      margin-right: 1rem;
    }
  }

  &__title {
    font-weight: bold;
    text-decoration: none;
  }

  &__text {
    margin-top: $grid-gutter-width/2;
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  &__meta {
    margin-left: auto;
    color: $color-text-light;

    .icon {
      vertical-align: -3px;
      margin-right: .5rem;
    }

    @include bp($screen-sm, max){
      width: 100%;
      margin-top: .25rem;
    }
  }

  &__button {
    margin-left: $grid-gutter-width;
  }

  &__quote {
    position: relative;
    color: $color-1;
    padding-left: 6rem;
    margin-top: 1.5rem;

    strong {
    }

    p {
      display: inline;
      margin: 0;
    }

    &:before {
      content: "";
      position: absolute;
      width: 23px;
      height: 23px;
      top: .25rem;
      left: 2rem;
      background-image: url("#{$img}/arrow.svg");
    }

    &:after {
      content: "";
      position: absolute;
      top: .25rem;
      left: 4.5rem;
      bottom: .25rem;
      width: 3px;
      background-color: $color-1;
    }
  }
}

.article-list {
  .article {
    margin-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    border-bottom: 2px dotted $color-gray;

    &:first-child {
      margin-top: 0;
    }
  }
}