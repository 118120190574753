@mixin circle($diameter) {
  width: $diameter;
  height: $diameter;
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
}

@mixin justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin overlay($position) {
  position: $position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin bp($point, $type: "min", $dimension: "width") {
  @if $type == "max" {
    $point: $point - 1;
  }

  @media (#{$type}-#{$dimension}: $point) {
    @content;
  }
}

@mixin highdpi {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin dot($diameter, $color, $border: false) {
  content: "";
  position: absolute;
  width: $diameter;
  height: $diameter;
  background-color: $color;

  @if ($border) {
    border: 1px solid $color;
    background-color: #ffffff;
  }
}