.menu-main {
  display: flex;
  align-items: center;
  height: 45px;

  &__item {
    margin-left: 2.5rem;
    color: #ffffff;
    text-decoration: none;

    &:hover {
      color: #ffffff;
      text-decoration: underline;
    }

    &:first-child {
      margin-left: 0;
    }

    &.active {
      text-decoration: underline;
    }
  }
}