html {
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

::selection {
  background-color: $color-2;
  color: $color-text;
}

body {
  height: 100%;
  margin: 0;
  overflow-y: scroll;
  background: url("#{$img}/bg.png") repeat $color-gray-light;
  color: $color-text;
  font-family: $font-1;
  font-size: $font-size;
  font-weight: normal;
  line-height: $line-height;
}