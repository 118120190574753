img {
  vertical-align: top;
  max-width: 100%;
}

video {
  max-width: 100%;
}

iframe {
  border: 0;
}